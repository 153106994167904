import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div>
          Alloy Fund
        </div>
        <div style={{marginBottom: '50px', fontSize: '17px', width: '500px'}}>
          Investing in impressive early-stage founders.
        </div>
        <div style={{fontSize: 14, color: '#a5a5a5'}}>
          <strong>Investors:{" "}</strong> 
            <a style={{color: 'inherit'}} href="https://www.linkedin.com/in/faviero" rel="noreferrer" target="_blank">Bruno</a>{", "}
            <a style={{color: 'inherit'}} href="https://www.linkedin.com/in/maxkolysh/" rel="noreferrer" target="_blank">Max</a>
             <br/>
            <br/>
        </div>
        <div style={{fontSize: 14, color: '#a5a5a5'}}>
          <strong>Select Portfolio Companies:{" "}</strong><br/>
            <a style={{color: 'inherit'}} href="https://www.monadlabs.xyz/" rel="noreferrer" target="_blank">Monad</a>{", "}
            <a style={{color: 'inherit'}} href="https://www.alpenlabs.io/" rel="noreferrer" target="_blank">Alpen</a>{", "}
            <a style={{color: 'inherit'}} href="https://movementlabs.xyz/" rel="noreferrer" target="_blank">Movement</a>{", "}
            <a style={{color: 'inherit'}} href="https://www.3rm.co/" rel="noreferrer" target="_blank">3RM</a>{", "}
            <a style={{color: 'inherit'}} href="https://www.helius.dev/" rel="noreferrer" target="_blank">Helius</a>{", "}
            <a style={{color: 'inherit'}} href="https://opengradient.ai/" rel="noreferrer" target="_blank">OpenGradient</a>{", "}
            <a style={{color: 'inherit'}} href="https://www.credal.ai/" rel="noreferrer" target="_blank">Credal</a>{", "}
            <a style={{color: 'inherit'}} href="https://www.coframe.com/" rel="noreferrer" target="_blank">CoFrame</a>{", "}
            <a style={{color: 'inherit'}} href="https://www.vellum.ai/" rel="noreferrer" target="_blank">Vellum</a>
            <br/>
            <a style={{color: 'inherit'}} href="https://zbiotics.com/" rel="noreferrer" target="_blank">ZBiotics</a>{", "}
            <a style={{color: 'inherit'}} href="https://www.nitra.com/" rel="noreferrer" target="_blank">Nitra</a>{", "}
            <a style={{color: 'inherit'}} href="https://www.slingshot.xyz/" rel="noreferrer" target="_blank">Slingshot AI</a>{", "}
            <a style={{color: 'inherit'}} href="https://www.polimorphic.com/" rel="noreferrer" target="_blank">Polimorphic</a>{", "}
            <a style={{color: 'inherit'}} href="https://www.pangeabio.com/" rel="noreferrer" target="_blank">Pangea Bio</a>{", "}
            <a style={{color: 'inherit'}} href="https://www.anagenex.com/" rel="noreferrer" target="_blank">Anagenex</a>{", "}
            <a style={{color: 'inherit'}} href="https://ansabio.com/" rel="noreferrer" target="_blank">Ansa Bio</a>
             <br/>
        </div>
        <div style={{fontSize: 14, color: '#a5a5a5'}}>
          <p style={{margin: 0}}>Reach out at hi@alloyfund.com</p>
        </div>
      </header>
    </div>
  );
}

export default App;
